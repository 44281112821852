<template>
  <div>
    <h3>Run a fetch</h3>
    <p>You can trigger a fetch of one of your bank:</p
    ><ul
      ><li v-for="item in useraccounts" :key="item._jv.id"
        ><button @click="newFetch(item._jv.id)">{{ item._jv.id }}</button></li
      ></ul
    >
    <h3>History of fetches</h3>
    <p>The database contains {{ tentativeNumberOfFetches }} fetches. </p>
    <ul
      ><li><a @click="reloadFetches">reload the full history</a></li></ul
    >
    <b-table striped hover per-page="10" :items="fetches">
      <template v-slot:cell(reload_state)="data">
        <b-spinner
          v-if="!['Completed', 'Failed'].includes(data.item.state)"
          @click="reloadFetch(data.item.id)"
        ></b-spinner>
        <span v-else></span>
      </template>
    </b-table>
  </div>
</template>

<script>
function myDictToList(obj) {
  // eslint-disable-next-line no-useless-computed-key
  const { ['_jv']: _, ['useraccount']: __, ...rest } = obj
  return {
    id: obj._jv.id,
    reload_state: obj._jv.id,
    useraccount: obj._jv.relationships.useraccount.data.id,
    ...rest,
  }
}

export default {
  computed: {
    fetches() {
      return Object.values(this.$store.getters['api/get']('fetches'))
        .map((x) => myDictToList(x))
        .reverse()
    },
    useraccounts() {
      return Object.values(this.$store.getters['api/get']('useraccounts'))
    },
    tentativeNumberOfFetches() {
      // TODO: retourner "querying" si la data n'est pas encore loadée
      return Object.keys(this.fetches).length
    },
  },
  created() {
    this.$store.dispatch('api/get', 'fetches')
    this.$store.dispatch('api/get', 'useraccounts')
    this.updateRunningFetches()
  },
  methods: {
    newFetch: function(useraccount) {
      this.$store.dispatch('api/post', {
        _jv: {
          type: 'fetches',
          relationships: {
            useraccount: {
              data: {
                type: 'useraccounts',
                id: useraccount,
              },
            },
          },
        },
      })
      this.updateRunningFetches()
    },
    reloadFetches: function() {
      this.$store.dispatch('api/get', 'fetches')
    },
    reloadFetch: function(id) {
      this.$store.dispatch('api/get', `fetches/${id}`)
    },
    updateRunningFetches: function() {
      let runs = 0
      console.error('run update')
      this.fetches.forEach((fetch) => {
        if (!['Completed', 'Failed'].includes(fetch.state)) {
          this.reloadFetches(fetch.id)
          console.error('run update fetch ' + fetch.id)
          runs += 1
        }
      })
      if (runs > 0) {
        setTimeout(function() {
          this.updateRunningFetches()
        }, 3000)
      }
    },
  },
}
</script>
