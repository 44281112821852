<template>
  <Layout>
    <h1>CamSync Fetches</h1>
    <CamsyncHistory />
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'
import CamsyncHistory from '@components/CamsyncHistory.vue'

export default {
  page: {
    title: 'Legacy Fetches',
    meta: [{ name: 'description', content: 'Fetch bank statements' }],
  },
  components: { Layout, CamsyncHistory },
}
</script>
